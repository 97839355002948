<template>
  <DailyAndMonthlyKpi
    :asup-id="asup.id"
    :timezone="asup.timezone"
    :get-kpi-data="getKpiDataFunc()"
    day-card-title="Job Success Rate per day"
    day-chart-y-axis-title="Job Count"
    month-card-title="Job Success Rate per month"
    month-chart-y-axis-title="Job Count"
  />
</template>

<script>
import DailyAndMonthlyKpi from '@/components/kpi/DailyAndMonthlyKpi.vue'
import AvamarService from '@/service/avamar.service'

export default {
  components: {
    DailyAndMonthlyKpi,
  },
  props: {
    asup: {
      type: Object,
      default: () => {},
    },
    asset: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    getKpiDataFunc() {
      return AvamarService.getJobKpiAsync
    },
  },
}
</script>
