// eslint-disable-next-line import/no-cycle
import axios from '@/libs/axios'
import { buildAxiosRequestConfig, parseGetParams } from './serviceManager'

export default {
  async getAllListAsync(params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params

    if (!requestConfig.params.maxResultCount) {
      requestConfig.params.maxResultCount = 50
    }

    let page = 0
    let totalCount = 0

    const result = []

    do {
      requestConfig.params.skipCount = page * requestConfig.params.maxResultCount

      // eslint-disable-next-line no-await-in-loop
      const response = await axios.get('/autosupport/avamar', requestConfig)
      totalCount = response.data.totalCount
      result.push(...response.data.items)

      page += 1
    } while (result.length < totalCount)

    return result
  },

  async getAsync(id, config) {
    const result = await axios.get(`/autosupport/avamar/${id}`, buildAxiosRequestConfig(config))
    return result.data
  },

  async triggerBuildAsync(id, params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params
    requestConfig.paramsSerializer = parseGetParams

    const result = await axios.post(`/autosupport/avamar/${id}/build`, {}, requestConfig)
    return result.data
  },

  async triggerBuildAllAsync(params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params
    requestConfig.paramsSerializer = parseGetParams

    const result = await axios.post('/autosupport/avamar/build', {}, requestConfig)
    return result.data
  },

  async getStatisticListAsync(id, params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params
    requestConfig.paramsSerializer = parseGetParams

    const result = await axios.get(`/autosupport/avamar/${id}/statistic`, requestConfig)
    return result.data
  },

  async deleteShouldRunReferenceListAsync() {
    const result = await axios.delete('/autosupport/avamar/should-run-reference/all')
    return result.data
  },

  async getShouldRunReferenceListAsync(id, params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params
    requestConfig.paramsSerializer = parseGetParams

    const result = await axios.get('/autosupport/avamar/should-run-reference', requestConfig)
    return result.data
  },

  async getShouldRunReferenceCrossCheckListAsync(id, params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params
    requestConfig.paramsSerializer = parseGetParams

    const result = await axios.get('/autosupport/avamar/should-run-reference/cross-check', requestConfig)
    return result.data
  },

  async updateReferenceListFileAsync(file) {
    const formData = new FormData()
    formData.append('input', file)

    const result = await axios.put('/autosupport/avamar/should-run-reference/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return result.data
  },

  async getAllStatisticListAsync(id, params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params

    if (!requestConfig.params.maxResultCount) {
      requestConfig.params.maxResultCount = 1000
    }

    requestConfig.paramsSerializer = parseGetParams

    let page = 0
    let totalCount = 0

    const result = []

    do {
      requestConfig.params.skipCount = page * requestConfig.params.maxResultCount

      // eslint-disable-next-line no-await-in-loop
      const response = await axios.get(`/autosupport/avamar/${id}/statistic`, requestConfig)
      totalCount = response.data.totalCount
      result.push(...response.data.items)

      page += 1
    } while (result.length < totalCount)

    return result
  },

  async getAllEventListAsync(id, params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params

    if (!requestConfig.params.maxResultCount) {
      requestConfig.params.maxResultCount = 1000
    }

    requestConfig.paramsSerializer = parseGetParams

    let page = 0
    let totalCount = 0

    const result = []

    do {
      requestConfig.params.skipCount = page * requestConfig.params.maxResultCount

      // eslint-disable-next-line no-await-in-loop
      const response = await axios.get(`/autosupport/avamar/${id}/events`, requestConfig)
      totalCount = response.data.totalCount
      result.push(...response.data.items)

      page += 1
    } while (result.length < totalCount)

    return result
  },

  async getClientPrimaryMatrixAsync(id, params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params
    requestConfig.paramsSerializer = parseGetParams

    const result = await axios.get(`/autosupport/avamar/${id}/client/primary-matrix`, requestConfig)
    return result.data
  },

  async getClientSecondaryMatrixAsync(id, params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params
    requestConfig.paramsSerializer = parseGetParams

    const result = await axios.get(`/autosupport/avamar/${id}/client/secondary-matrix`, requestConfig)
    return result.data
  },

  async getClientAsync(systemId, client, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.paramsSerializer = parseGetParams

    const result = await axios.get(`/autosupport/avamar/${systemId}/client/${client}`, requestConfig)
    return result.data
  },

  async getJobAsync(systemId, jobId, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.paramsSerializer = parseGetParams

    const result = await axios.get(`/autosupport/avamar/${systemId}/job/${jobId}`, requestConfig)
    return result.data
  },

  async getJobListAsync(systemId, params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params
    requestConfig.paramsSerializer = parseGetParams

    const result = await axios.get(`/autosupport/avamar/${systemId}/job`, requestConfig)
    return result.data
  },

  async getJobKpiAsync(systemId, params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params
    requestConfig.paramsSerializer = parseGetParams

    const result = await axios.get(`/autosupport/avamar/${systemId}/job/kpi`, requestConfig)
    return result.data
  },

  async getJobDurationListAsync(systemId, params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params
    requestConfig.paramsSerializer = parseGetParams

    const result = await axios.get(`/autosupport/avamar/${systemId}/job/duration`, requestConfig)
    return result.data
  },
}
